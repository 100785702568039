import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { get } from 'lodash'

import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import Edit from '@mui/icons-material/Edit'

import { ReimbursementVCLossTypes } from 'shared/constants/incomeApproach/commercialIncome'

import { EXPENSE_CATEGORIES } from '../../../../../../../../shared/constants/expenses'

import TableThemeProvider from '../../../../../components/TableThemeProvider'
import TableCheckbox from '../../../../../../shared/components/Table/TableCheckbox'
import TableNumber from '../../../../../../shared/components/Table/TableNumber'
import { GeneratedComment, RadioButtonList } from '../../../../../../shared/components'
import NumberField from '../../../../../../shared/components/Number'
import { positive } from '../../../../../../shared/utils/validation'
import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'
import { fromPercents, toPercents } from '../../../../../../shared/utils/numberOperations'
import { REIMBRUSEMENT_VC_LOSS_OPTIONS } from '../constants'

import ReimbursementEditModal from './ReimbursementEditModal'
import styles from './styles'

export class DollarAmountReimbursementTable extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    reimbursement: PropTypes.object.isRequired,
    expenseCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    pathPrefix: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  }

  state = {
    isEditModalOpen: false,
  }

  getFieldValue = (name, defaultValue) => {
    const { form } = this.props
    return get(form.values, name, defaultValue)
  }
  onFieldChange = (fieldName, unitReimbursementPath) => {
    const { form } = this.props
    form.mutators.updateDollarAmount({ unitReimbursementPath, form })
  }
  updateVCLossValues = () => {
    const { form, pathPrefix } = this.props
    form.mutators.updateVCLossValues({ reimbursementPath: pathPrefix })
  }
  updateVCLossTypeAndValues = event => {
    const vcLossType = event.target.value
    const { form, pathPrefix } = this.props
    form.mutators.updateVCLoss({ reimbursementPath: pathPrefix, vcLossType, form })
  }

  updateReimbursement = updatedReimbursement => {
    const { onUpdate, reimbursement } = this.props
    onUpdate(updatedReimbursement, reimbursement)
    this.closeEditModal()
  }
  deleteReimbursement = reimbursement => {
    const { onDelete } = this.props
    onDelete(reimbursement)
  }
  openEditModal = () => {
    this.setState({ isEditModalOpen: true })
  }
  closeEditModal = () => {
    this.setState({ isEditModalOpen: false })
  }

  renderUnitReimbursementTable() {
    const { reimbursement, pathPrefix, classes } = this.props
    const monthlyAmountKnown = reimbursement.knownInformation.dollarAmountType === 'Monthly'

    return (
      <Table className={classes.reimbursementTable}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.enabledColumn} />
            <TableCell>Tenant Name</TableCell>
            {monthlyAmountKnown && <TableCell>Monthly Reimbursement</TableCell>}
            <TableCell>Annual Reimbursement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name={`${pathPrefix}.unitReimbursements`}>
            {({ fields }) =>
              fields.map((field, index) => this.renderUnitReimbursementRow(field, index, monthlyAmountKnown))
            }
          </FieldArray>
          <TableRow>
            <TableCell />
            {monthlyAmountKnown && <TableCell />}
            <TableCell>Total:</TableCell>
            <TableCell>{formatCurrencyFloat(reimbursement.totalAnnual)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  renderUnitReimbursementRow(unitReimbursementPath, index, monthlyAmountKnown) {
    const { classes } = this.props
    const enabled = this.getFieldValue(`${unitReimbursementPath}.enabled`)

    return (
      <TableRow key={index}>
        <TableCell className={classes.enabledColumn}>
          <TableCheckbox
            name={`${unitReimbursementPath}.enabled`}
            handleChange={() => this.onFieldChange('enabled', unitReimbursementPath)}
          />
        </TableCell>

        <TableCell>{this.getFieldValue(`${unitReimbursementPath}.unit.tenantName`)}</TableCell>
        {monthlyAmountKnown && (
          <TableCell>
            <TableNumber
              name={`${unitReimbursementPath}.monthly`}
              decimalScale={2}
              allowNegative={false}
              prefix="$"
              validate={positive}
              thousandSeparator
              handleChange={() => this.onFieldChange('monthly', unitReimbursementPath)}
              disabled={!enabled}
            />
          </TableCell>
        )}
        <TableCell>
          {monthlyAmountKnown ? (
            formatCurrencyFloat(this.getFieldValue(`${unitReimbursementPath}.annual`, 0))
          ) : (
            <TableNumber
              name={`${unitReimbursementPath}.annual`}
              decimalScale={2}
              allowNegative={false}
              prefix="$"
              validate={positive}
              thousandSeparator
              handleChange={this.updateVCLossValues}
              disabled={!enabled}
            />
          )}
        </TableCell>
      </TableRow>
    )
  }

  renderVCLossSection() {
    const { pathPrefix, reimbursement, form } = this.props
    const expenseType = EXPENSE_CATEGORIES[reimbursement.expenseType] || reimbursement.expenseType

    const reimbusementVCLossType = get(form.values, `${pathPrefix}.vcLossType`)
    const isCommercialLossType = reimbusementVCLossType === ReimbursementVCLossTypes.COMMERCIAL
    return (
      <Grid container>
        <Grid item xs={4}>
          <RadioButtonList
            horizontal
            items={REIMBRUSEMENT_VC_LOSS_OPTIONS}
            label="V/C Loss Type"
            name={`${pathPrefix}.vcLossType`}
            onChange={event => this.updateVCLossTypeAndValues(event)}
          />
          {!isCommercialLossType && (
            <NumberField
              name={`${pathPrefix}.vcLoss`}
              label="V/C Loss %"
              adornment="%"
              format={toPercents}
              normalize={fromPercents}
              decimalScale={2}
              min={0}
              max={100}
              handleChange={this.updateVCLossValues}
              thousandSeparator
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <NumberField
            name={`${pathPrefix}.grossVcLoss`}
            label="Gross V/C Loss"
            decimalScale={2}
            allowNegative={false}
            prefix="$"
            validate={positive}
            thousandSeparator
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            name={`${pathPrefix}.effectiveAnnual`}
            label={`Effective ${expenseType} Reimbursement`}
            decimalScale={2}
            allowNegative={false}
            prefix="$"
            validate={positive}
            thousandSeparator
            disabled
          />
        </Grid>
      </Grid>
    )
  }

  generateDiscussion = values => {
    const { reimbursement } = this.props

    const totalAnnual = formatCurrencyFloat(reimbursement.totalAnnual)
    const expenseType = (EXPENSE_CATEGORIES[reimbursement.expenseType] || reimbursement.expenseType).toLowerCase()

    return `According to our projections, the total ${expenseType} reimbursement is ${totalAnnual} per year.`
  }

  renderDiscussion() {
    const { pathPrefix, reimbursement } = this.props
    return (
      <Grid container>
        <Grid item xs={8}>
          <GeneratedComment
            dataPath={`${pathPrefix}.discussion`}
            label={`${reimbursement.name} Discussion`}
            title="Generated Commentary"
            tooltipText="The following text will appear in the Income Approach's Commercial Reimbursement Summary section of your report."
            getGeneratedText={this.generateDiscussion}
            isDynamicContent
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes, reimbursement, form, expenseCategories } = this.props
    const { isEditModalOpen } = this.state

    return (
      <React.Fragment>
        <div className={classes.titleContainer}>
          <Typography>{reimbursement.name}</Typography>
          <IconButton aria-label="edit" className={classes.iconButton} onClick={this.openEditModal}>
            <Edit color="primary" />
          </IconButton>
          <IconButton aria-label="delete" className={classes.iconButton} onClick={this.deleteReimbursement}>
            <DeleteOutlined color="error" />
          </IconButton>
        </div>
        {isEditModalOpen && (
          <ReimbursementEditModal
            reimbursement={reimbursement}
            expenseCategories={expenseCategories}
            units={form.values.units}
            onSave={this.updateReimbursement}
            onCancel={this.closeEditModal}
          />
        )}
        <Grid container>
          <Grid item xs={12}>
            <TableThemeProvider>{this.renderUnitReimbursementTable()}</TableThemeProvider>
            {this.renderVCLossSection()}
            {this.renderDiscussion()}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DollarAmountReimbursementTable)
