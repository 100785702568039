import React from 'react'

import { Paper, Stack, Typography } from '@mui/material'
import { toLower, get } from 'lodash'

import { Area, Template } from 'client-shared/components/Template'
import { GeneratedComment } from 'client-shared/components'
import { NumberComponent } from 'client-shared/components/Number/Number'
import { formatPercentageString, formatPercentageFloat, toPercentageString } from 'client-shared/utils/numberFormatters'
import { getLowerCasedUseLabel } from 'shared/helpers/commercialUses'
import { ReimbursementVCLossTypes } from 'shared/constants/incomeApproach/commercialIncome'
import { roundToFourDecimals } from 'shared/utils/numberOperations'

import CommercialLossSection from './CommercialLossSection'

const getCommercialVacancyLossDiscussion = (values, uniqueUses) => {
  const paragraphs = []
  const commercialVCLossPercentageAggregate = roundToFourDecimals(get(values, 'commercialVCLossPercentageAggregate'))
  uniqueUses.forEach(use => {
    const displayUse = getLowerCasedUseLabel(use)
    const subjectAreaRate = formatPercentageString(get(values, `subjectAreaCommercialVacancy.${use}`))
    const commercialVCLossPercentageByType = toPercentageString(get(values, `commercialVCLossPercentageByType.${use}`))
    const commercialSubjectSuitability = toLower(get(values, `commercialSubjectSuitability.${use}`)) || '[UNDEFINED]'

    paragraphs.push(
      `As indicated, ${displayUse} vacancy in the subject's submarket is currently at` +
        ` approximately ${subjectAreaRate}, and the subject's specific location is ${commercialSubjectSuitability}` +
        ` for ${displayUse} space. Considering these factors, as well as investor expectations for` +
        ` ${displayUse} vacancy and collection loss, we have applied a ${commercialVCLossPercentageByType}` +
        ` vacancy and collection allowance to the ${displayUse} income.`
    )
  })

  paragraphs.push(
    'These conclusions also consider the fact that the tenant may vacate at some point during a five or ten year leasing cycle.'
  )

  const reimbursementsParagraph = values.commercialReimbursementSummary
    .map(reimbursement => {
      const reimbursementLabel = reimbursement.reimbursementLabelLowerCased
      const { vcLossType } = reimbursement
      const vcLoss =
        vcLossType === ReimbursementVCLossTypes.COMMERCIAL ? commercialVCLossPercentageAggregate : reimbursement.vcLoss
      const lossPercentage = formatPercentageString(vcLoss)

      return (
        `We have applied a vacancy and collection loss of ${lossPercentage} to the` +
        ` ${reimbursementLabel} reimbursement, which has been added to our Pro Forma.`
      )
    })
    .join(' ')
  if (reimbursementsParagraph) {
    paragraphs.push(reimbursementsParagraph)
  }

  if (paragraphs.length > 1) {
    return `${paragraphs.join('\r\n')}`
  } else {
    return paragraphs[0]
  }
}

class CommercialVacancyLoss extends React.PureComponent {
  getCommercialVacancyLossParagraph = values => {
    const { uniqueUses } = this.props
    return getCommercialVacancyLossDiscussion(values, uniqueUses)
  }

  render() {
    const { uniqueUses, form } = this.props
    const commercialVCLossAmount = parseFloat(get(form.values, 'commercialVCLossAmount', 0))
    const commercialVCLossPercentageAggregate = formatPercentageFloat(
      get(form.values, 'commercialVCLossPercentageAggregate', 0)
    )
    return (
      <Paper>
        <Stack spacing={2}>
          <Typography variant="h6">Commercial Vacancy and Collection Loss</Typography>
          {uniqueUses.map(use => {
            return use && <CommercialLossSection form={form} use={use} key={use} />
          })}
          <Template
            is={`
            'inputPercentage  inputAmount'
            'commentary       commentary'
            `}
            gap={2}
          >
            <Area is="inputPercentage">
              <NumberComponent
                adornment="%"
                decimalScale={2}
                disabled
                label="Gross Commercial Vacancy and Collection Loss Percentage"
                name="commercialVCLossPercentageAggregate"
                value={commercialVCLossPercentageAggregate}
              />
            </Area>
            <Area is="inputAmount">
              <NumberComponent
                disabled
                label="Gross Commercial Vacancy and Collection Loss"
                name="commercialVCLossAmount"
                prefix="$"
                thousandSeparator
                value={Math.abs(commercialVCLossAmount)}
              />
            </Area>
            <Area is="commentary">
              <GeneratedComment
                dataPath="commercialVCLossDiscussion"
                getGeneratedText={this.getCommercialVacancyLossParagraph}
                isDynamicContent
                label="Commercial V&C Loss Discussion"
                sx={{ width: 1 }}
                title="Generated Commentary"
              />
            </Area>
          </Template>
        </Stack>
      </Paper>
    )
  }
}

export default CommercialVacancyLoss
