export const LeaseStatusTypes = {
  Occupied: 'Occupied',
  Vacant: 'Vacant',
  OwnerOccupied: 'Owner Occupied',
}

export const OCCUPANCY_RATE_BASIS_OPTIONS = [{ value: 'unit', label: 'Unit' }, { value: 'sf', label: 'SF' }]

export const OCCUPANCY_RATE_BASIS_TYPES = {
  unit: 'unit',
  sf: 'sf',
}

export const ReimbursementVCLossTypes = {
  COMMERCIAL: 'Commercial',
  REIMBURSEMENT_VC: 'Reimbursement V/C',
}

export const REIMBURSEMENT_TYPES = {
  increaseOverBaseYear: { value: 'increaseOverBaseYear', label: 'Increase Over Base Year' },
  dollarAmount: { value: 'dollarAmount', label: 'Dollar Amount' },
  percentOfCurrentYearLiability: { value: 'percentOfCurrentYearLiability', label: '% of Current Year Liability' },
  percentOfAppraiserForecast: { value: 'percentOfAppraiserForecast', label: '% of Appraiser Forecast' },
}
