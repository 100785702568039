import { COMMERCIAL_REIMBURSEMENT_SUMMARY_PATH } from 'shared/constants/report/keysAndDataPaths'
import { ReimbursementVCLossTypes, REIMBURSEMENT_TYPES } from 'shared/constants/incomeApproach/commercialIncome'

import { arrayToKeyValuePairs, objectToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'

export const DATA_PATH = COMMERCIAL_REIMBURSEMENT_SUMMARY_PATH

export const Labels = {
  HEADING_NAME: 'Reimbursement Summary',
  TENANT_NAME: 'Tenant',
  BASE_YEAR: 'Base Year',
  CURRENT_YEAR_LIABILITY: 'Current Year Liability',
  BASE_YEAR_ASSESMENT: 'Base Year Assessment ($)',
  BASE_YEAR_TAX_RATE: 'Base Year Tax Rate',
  BASE_YEAR_LIABILITY: 'Base Year Liability',
  INCREASE: 'Increase',
  REIMBURSEMENT_SHARE: 'Reimbursement Share (%)',
  ANNUAL_REIMBURSEMENT: 'Annual Reimbursement',
}

export const TAX_REIMBURSEMENT_TYPE_OPTIONS = [
  REIMBURSEMENT_TYPES.increaseOverBaseYear,
  REIMBURSEMENT_TYPES.percentOfCurrentYearLiability,
  REIMBURSEMENT_TYPES.dollarAmount,
]
export const OTHER_REIMBURSEMENT_TYPE_OPTIONS = [
  REIMBURSEMENT_TYPES.percentOfAppraiserForecast,
  REIMBURSEMENT_TYPES.dollarAmount,
]

export const DOLLAR_AMOUNT_TYPE_OPTIONS = arrayToKeyValuePairs(['Monthly', 'Annual'])
export const BASE_YEAR_VALUE_TYPE_OPTIONS = arrayToKeyValuePairs(['Base Year Assessment', 'Base Year Liability'])

export const REIMBRUSEMENT_VC_LOSS_OPTIONS = objectToKeyValuePairs(ReimbursementVCLossTypes)

export { REIMBURSEMENT_TYPES }
