import React from 'react'

import { camelCase, get, map } from 'lodash'

import { Paper, Table, TableBody, TableCell, TableRow } from '@mui/material'

import IncomeApproachCalculations from 'shared/report-calculations/income-approach/income-approach-calculations'
import { ReimbursementVCLossTypes } from 'shared/constants/incomeApproach/commercialIncome'
import { roundToFourDecimals } from 'shared/utils/numberOperations'

import { getCommercialLossLabel, getCommercialIncomeLabel } from '../../../../../../shared/helpers/commercialUses'
import { formatCurrencyFloat, toPercentageString } from '../../../../shared/utils/numberFormatters'

import { Labels } from './PotentialGrossIncomeConstants'

const getVCPercentage = (vcType, vcPercentage = 0, residentialVCLossPercentage) =>
  vcType === 'residential' ? residentialVCLossPercentage : vcPercentage
class SummaryTable extends React.PureComponent {
  renderSummaryTableRow = (label, value, isTotalRow, isLossRow = false) => (
    <TableRow data-qa={`${camelCase(label)}-row`} key={label}>
      <TableCell sx={{ ...(isTotalRow && { fontWeight: 700 }) }}>{label}</TableCell>
      <TableCell
        data-qa="value-cell"
        sx={{
          textAlign: 'right',
          ...(isTotalRow && { fontWeight: 700 }),
        }}
      >
        {formatCurrencyFloat(isLossRow ? value * -1 : value)}
      </TableCell>
    </TableRow>
  )

  renderCommercialReimbursementRows = () => {
    const { form } = this.props
    const { commercialReimbursementSummary } = form.values

    return (
      <>
        {commercialReimbursementSummary.map(reimbursement => {
          const label = `Potential ${reimbursement.reimbursementLabel} Reimbursement`
          return this.renderSummaryTableRow(label, reimbursement.totalAnnual.total)
        })}
      </>
    )
  }
  renderCommercialReimbursementVCLossRows = () => {
    const { form } = this.props
    const { commercialReimbursementSummary, commercialVCLossPercentageAggregate } = form.values
    return (
      <>
        {commercialReimbursementSummary
          .filter(reimbursement => reimbursement.vcLoss > 0)
          .map(reimbursement => {
            const isUsingCommercialVCLoss = reimbursement.vcLossType === ReimbursementVCLossTypes.COMMERCIAL
            const roundedCommercialVCLoss = roundToFourDecimals(commercialVCLossPercentageAggregate)
            const vcLoss = isUsingCommercialVCLoss ? roundedCommercialVCLoss : reimbursement.vcLoss
            const label = `Less ${reimbursement.reimbursementLabel} Reimbursement V/C Loss @ ${toPercentageString(
              vcLoss
            )}`
            const grossVcLossTotal = isUsingCommercialVCLoss
              ? reimbursement.totalAnnual.total * vcLoss * -1
              : reimbursement.grossVcLoss.total
            return this.renderSummaryTableRow(label, grossVcLossTotal, false, false)
          })}
      </>
    )
  }

  renderCommercialSummaryRows = () => {
    const { form, uniqueUses } = this.props
    const { potentialCommercialIncomeByType } = form.values

    return (
      <React.Fragment>
        {uniqueUses.map(use => {
          const label = use ? getCommercialIncomeLabel(use) : 'Commercial Income'
          return this.renderSummaryTableRow(label, potentialCommercialIncomeByType[use])
        })}
      </React.Fragment>
    )
  }

  renderCommercialLossSummaryRows = () => {
    const { form, uniqueUses } = this.props
    const { commercialVCLossPercentageByType, commercialVCLossAmountByType } = form.values

    return (
      <React.Fragment>
        {uniqueUses.map(use => {
          const percentage = commercialVCLossPercentageByType[use]
          const lossAmount = commercialVCLossAmountByType[use]
          const label = getCommercialLossLabel(use, percentage)
          return this.renderSummaryTableRow(label, lossAmount, false, true)
        })}
      </React.Fragment>
    )
  }

  renderHeadingTableRow = (label, value) => (
    <TableRow>
      <TableCell sx={{ fontWeight: 700 }}>{label}</TableCell>
      <TableCell sx={{ fontWeight: 700, textAlign: 'right' }}>{value}</TableCell>
    </TableRow>
  )

  render() {
    const { hasCommercial, form, uniqueUses } = this.props
    const values = form.values

    const commercialVCLossAmount = hasCommercial ? get(values, 'commercialVCLossAmount') : 0
    const potentialResidentialIncome = get(values, 'potentialResidentialIncome', 0)
    const residentialVCLossPercentage = get(values, 'residentialVCLossPercentage', 0)
    const commercialVCLoss = get(values, 'commercialVCLossPercentageAggregate', 0)

    let residentialVCLossAmount = 0
    if (values && !isNaN(potentialResidentialIncome) && !isNaN(residentialVCLossPercentage)) {
      residentialVCLossAmount = IncomeApproachCalculations.calculateVCLossAmount(
        potentialResidentialIncome,
        residentialVCLossPercentage
      )
    }

    const annualLaundryIncome = get(values, 'annualLaundryIncome', 0)
    const annualOtherIncome = get(values, 'annualOtherIncome', 0)
    const annualParkingIncome = get(values, 'annualParkingIncome', 0)
    const annualStorageIncome = get(values, 'annualStorageIncome', 0)
    const otherIncome = map(values.otherIncome, otherIncomeItem => {
      const vcPercentage = getVCPercentage(otherIncomeItem.vcType, otherIncomeItem.vc, residentialVCLossPercentage)
      const vcLossAmount = IncomeApproachCalculations.calculateVCLossAmount(otherIncomeItem.amount, vcPercentage)

      return {
        amount: otherIncomeItem.amount,
        category: otherIncomeItem.category,
        vcLossAmount,
        vcPercentage,
      }
    })
    const otherIncomeVCLossAmount = otherIncome.reduce(
      (accumulator, otherIncomeItem) => accumulator + otherIncomeItem.vcLossAmount,
      0
    )

    const potentialGrossIncome = get(values, 'potentialGrossIncome', 0)
    const laundryPercentage = getVCPercentage(values.laundryVcType, values.laundryVc, residentialVCLossPercentage)
    const laundryVCLossAmount = IncomeApproachCalculations.calculateVCLossAmount(annualLaundryIncome, laundryPercentage)
    const storagePercentage = getVCPercentage(values.storageVcType, values.storageVc, residentialVCLossPercentage)
    const storageVCLossAmount = IncomeApproachCalculations.calculateVCLossAmount(annualStorageIncome, storagePercentage)

    const parkingPercentage = getVCPercentage(
      values.parkingVcType,
      values.parkingVc,
      values.residentialVCLossPercentage
    )
    const parkingVCLossAmount = IncomeApproachCalculations.calculateVCLossAmount(annualParkingIncome, parkingPercentage)

    const commercialReimbursementSummary = get(values, 'commercialReimbursementSummary', [])
    const commercialReimbursementSummaryTotal = commercialReimbursementSummary.reduce((accumulator, reimbursement) => {
      const { vcLossType, totalAnnual } = reimbursement
      const isUsingCommercialVCLoss = vcLossType === ReimbursementVCLossTypes.COMMERCIAL
      const vcLoss = isUsingCommercialVCLoss ? roundToFourDecimals(commercialVCLoss) : reimbursement.vcLoss
      const grossVcLossTotal = totalAnnual.total * vcLoss
      return accumulator + grossVcLossTotal
    }, 0)
    const commercialReimbursementVCLoss = hasCommercial ? commercialReimbursementSummaryTotal : 0
    const totalCommercialVCLoss = commercialVCLossAmount + commercialReimbursementVCLoss
    const miscVCLossAmount = storageVCLossAmount + laundryVCLossAmount + parkingVCLossAmount + otherIncomeVCLossAmount
    const totalVCLosses = IncomeApproachCalculations.calculateTotalVCLosses(
      residentialVCLossAmount,
      totalCommercialVCLoss,
      miscVCLossAmount
    )
    const effectiveGrossIncome = IncomeApproachCalculations.calculateEffectiveGrossIncome(
      potentialGrossIncome,
      totalVCLosses
    )

    const { potentialCommercialIncomeByType } = form.values

    const incomeItems = [
      ...otherIncome.map(otherIncomeItem => otherIncomeItem.amount),
      ...uniqueUses.map(use => potentialCommercialIncomeByType[use]),
      potentialResidentialIncome,
      annualLaundryIncome,
      annualStorageIncome,
      annualParkingIncome,
    ]

    const shouldShowSeparateOtherLine = incomeItems.reduce((isAnyIncomeItemEqualToPGI, currentValue) => {
      return isAnyIncomeItemEqualToPGI || currentValue === potentialGrossIncome
    }, false)

    return (
      <Paper>
        <Table>
          <TableBody>
            {this.renderHeadingTableRow(Labels.INCOME_TYPE, '$')}
            {potentialResidentialIncome > 0 &&
              this.renderSummaryTableRow(Labels.POTENTIAL_RESIDENTIAL_INCOME, potentialResidentialIncome)}
            {hasCommercial && this.renderCommercialSummaryRows()}
            {hasCommercial && this.renderCommercialReimbursementRows()}
            {annualParkingIncome > 0 && this.renderSummaryTableRow(Labels.PARKING_INCOME, annualParkingIncome)}
            {annualLaundryIncome > 0 && this.renderSummaryTableRow(Labels.LAUNDRY_INCOME, annualLaundryIncome)}
            {annualStorageIncome > 0 && this.renderSummaryTableRow(Labels.STORAGE_INCOME, annualStorageIncome)}
            {annualOtherIncome > 0 &&
              otherIncome.map((oi, index) => (
                <React.Fragment key={index}>{this.renderSummaryTableRow(oi.category, oi.amount)}</React.Fragment>
              ))}
            {shouldShowSeparateOtherLine && (
              <React.Fragment>{this.renderSummaryTableRow('Other Income', '0')}</React.Fragment>
            )}
            {this.renderSummaryTableRow(Labels.POTENTIAL_GROSS_INCOME, potentialGrossIncome, true)}
            {!!residentialVCLossAmount &&
              this.renderSummaryTableRow(
                `${Labels.LESS_RESIDENTIAL_VC_LOSS} @ ${toPercentageString(residentialVCLossPercentage)}`,
                residentialVCLossAmount,
                false,
                true
              )}
            {!!commercialVCLossAmount && this.renderCommercialLossSummaryRows()}
            {hasCommercial && this.renderCommercialReimbursementVCLossRows()}
            {!!parkingVCLossAmount &&
              this.renderSummaryTableRow(
                `${Labels.LESS_PARKING_VC_LOSS} @ ${toPercentageString(parkingPercentage)}`,
                parkingVCLossAmount,
                false,
                true
              )}
            {!!laundryVCLossAmount &&
              this.renderSummaryTableRow(
                `${Labels.LESS_LAUNDRY_VC_LOSS} @ ${toPercentageString(laundryPercentage)}`,
                laundryVCLossAmount,
                false,
                true
              )}
            {!!storageVCLossAmount &&
              this.renderSummaryTableRow(
                `${Labels.LESS_STORAGE_VC_LOSS} @ ${toPercentageString(storagePercentage)}`,
                storageVCLossAmount,
                false,
                true
              )}
            {otherIncome.map((oi, index) => (
              <React.Fragment key={index}>
                {this.renderSummaryTableRow(
                  `${Labels.LESS} ${oi.category} ${Labels.VC_LOSS} @ ${toPercentageString(oi.vcPercentage)}`,
                  oi.vcLossAmount,
                  false,
                  true
                )}
              </React.Fragment>
            ))}
            {this.renderSummaryTableRow(Labels.EFFECTIVE_GROSS_INCOME, effectiveGrossIncome, true)}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

export default SummaryTable
